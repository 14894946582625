import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import "./App.css"; // Adjust the path if your CSS file is located elsewhere
import logo from "./views/images/DeWaLogo.png";
import wallet from "./views/images/dewaScreens.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import styled from "styled-components";
import cphlogo from "./views/images/cphfintechlogo.png";
import DTUlogo from "./views/images/DTUlogo.png";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

function MenuComponent({ onClose }) {
  const navigate = useNavigate();

  const handleClose = (path) => {
    onClose();
    if (path) {
      navigate(path);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "60px",
        left: "50%",
        transform: "translate(-50%, 0)",
        width: 300,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "10px",
      }}
    >
      <List>
        {["Home", "About", "Use Cases", "Partners", "Imprint"].map((text) => (
          <ScrollLink
            key={text}
            to={text.toLowerCase().replace(/\s/g, "")} // Replace with the appropriate name of the section to scroll to
            spy={true}
            smooth={true}
            offset={-70} // Adjust the offset as needed for your layout
            duration={500} // Duration of the scroll animation
          >
            <ListItem button>
              <ListItemText primary={text} />
            </ListItem>
          </ScrollLink>
        ))}
      </List>
    </Box>
  );
}

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const moreInfoRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef(null);
  const dynamicContainerStyle = {
    ...styles.container,
    ...(isMobileView ? { flexDirection: "column" } : {}),
  };
  const [selected, setSelected] = useState("eGov"); // Default selection
  const partnerData = [
    {
      name: "Denmarks Teknikse Universiteit",
      imageUrl: DTUlogo, // Replace with the actual image URL
      websiteUrl: "https://www.dtu.dk/", // Replace with the actual website URL
    },
    {
      name: "Copenhagen Fintech",
      imageUrl: cphlogo, // Replace with the actual image URL
      websiteUrl: "https://www.copenhagenfintech.dk/", // Replace with the actual website URL
    },
    // Add more partner data as needed
  ];

  const openPartnerWebsite = (websiteUrl) => {
    window.open(websiteUrl, "_blank");
  };

  const subtitlesContent = {
    eGov: (
      <div>
        <ReactiveSubTitle>Passport</ReactiveSubTitle>
        <ReactiveText>
          Discover the ease and security of your digital Passport, your trusted
          digital identity for online citizenship procedures. Easily prove your
          identity, with recognition across various online platforms, ensuring a
          seamless and secure experience.
        </ReactiveText>
        <ReactiveSubTitle>Drivers License</ReactiveSubTitle>
        <ReactiveText>
          Experience the convenience and security of a digital driving license,
          universally recognized across Europe. DeWa ensures your driving
          credentials are always at your fingertips, accepted by car rental
          agencies and law enforcement authorities everywhere in Europe. Travel
          with confidence, knowing your digital driving license is safeguarded
          and recognized in every corner of the EU.
        </ReactiveText>
      </div>
    ),
    studentID: (
      <div>
        <ReactiveSubTitle>Student ID</ReactiveSubTitle>
        <ReactiveText>
          Experience the convenience and security of DeWa Student ID,
          universally recognized at educational institutions worldwide. DeWa
          ensures your student credentials are always accessible, making your
          academic journey smoother and more efficient.
        </ReactiveText>
      </div>
    ),
    kyc: (
      <div>
        <ReactiveSubTitle>KYC & AML</ReactiveSubTitle>
        <ReactiveText>
          Explore the reliability and compliance of DeWa KYC/AML. Streamline
          your verification processes, ensuring regulatory compliance while
          maintaining a secure and efficient business environment.
        </ReactiveText>
      </div>
    ),
    healthcare: (
      <div>
        <ReactiveSubTitle>Health Card</ReactiveSubTitle>
        <ReactiveText>
          Discover the convenience and security of DeWa Health Card, universally
          accepted at healthcare facilities worldwide. DeWa ensures your medical
          records are always accessible, simplifying your healthcare journey
          while prioritizing privacy and security.
        </ReactiveText>
      </div>
    ),
    sso: (
      <div>
        <ReactiveSubTitle>Single Sign On (SSO)</ReactiveSubTitle>
        <ReactiveText>
          Experience the simplicity and security of DeWa SSO. Seamlessly access
          multiple online services with a single, secure login from your
          decentralized wallet. Enjoy hassle-free authentication while
          maintaining control over your personal data.
        </ReactiveText>
      </div>
    ),
    // Define other subtitles similarly
  };

  useEffect(() => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
  }, []);
  const horizontalNavigation = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0 30px",
      }}
    >
      {["Home", "About", "Use Cases", "Partners", "Imprint"].map(
        (text, index) => {
          const className = `nav-link nav-link-${text
            .toLowerCase()
            .replace(/\s/g, "")}`;
          return (
            <ScrollLink
              key={text}
              to={text.toLowerCase().replace(/\s/g, "")}
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed for your layout
              duration={500} // Duration of the scroll animation
              className={className}
            >
              {text}
            </ScrollLink>
          );
        }
      )}
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const primaryButtonStyle = {
    color: "primary", // Set the text color to grey
    // Add any other CSS properties as needed
    height: "50px",
    fontSize: "18px",
    marginBottom: "40px",
  };

  const [isImageVisible, setImageVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const handleImageClick = (src) => {
    setImageSrc(src);
    setImageVisible(true);
  };

  const closeImage = () => {
    setImageSrc(null);
    setImageVisible(false);
  };
  return (
    <Router>
      <div className="App">
        <AppBar
          position="fixed"
          style={{
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.76)", // Black background with 76% opacity
            borderRadius: "50px", // Rounded corners
            justifyContent: "flex-end",
            display: "flex",
            padding: 15,
            zIndex: 1100,
            top: "20px", // Padding from the top
            right: "20px", // Padding from the right
            left: "20px", // Padding from the left
            width: "calc(100% - 40px)", // Adjust the width
          }}
          elevation={0}
        >
          <Toolbar>
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: 60, maxHeight: 60 }}
            />
            <Typography
              variant="h4"
              style={{
                flexGrow: 1,
                textAlign: "left",
                marginLeft: "20px",
                fontWeight: "bold",
              }}
            >
              DeWa
            </Typography>
            {isMobileView && (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleOpen}
              >
                <MenuIcon />
              </IconButton>
            )}
            {isMobileView ? (
              <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="menu-modal"
              >
                <MenuComponent onClose={handleClose} />
              </Modal>
            ) : (
              // Horizontal navigation for larger screens
              <div></div>
            )}
            {!isMobileView && horizontalNavigation}
          </Toolbar>
        </AppBar>
        <div style={{ padding: "20px", marginTop: 100 }}>
          <Typography
            variant="h3"
            style={{
              fontSize: "38px",
              marginTop: "180px",
            }}
            id="home"
          >
            All of your ID's available at one place at all times
          </Typography>
          <Typography
            variant="h1"
            style={{
              fontWeight: "bold",
              fontSize: "70px",
              marginTop: 50,
            }}
          >
            Say Hi to DeWa
          </Typography>
        </div>
        <div>
          <ScrollLink
            to="about" // Replace with the appropriate name of the section to scroll to
            spy={true}
            smooth={true}
            offset={-70} // Adjust the offset as needed for your layout
            duration={500} // Duration of the scroll animation
          >
            <Button variant="text" style={primaryButtonStyle}>
              Learn More
              <ExpandMoreIcon />
            </Button>
          </ScrollLink>
        </div>
        <div className="moreInfo" ref={moreInfoRef}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "medium",
              fontSize: "18px",
              marginTop: 300,
              color: "grey",
            }}
          ></Typography>
          <div className="container">
            <Typography variant="h1" style={styles.title}>
              DeWa is your Decentralised Digital Identity Wallet
            </Typography>
            <Typography variant="h1" style={styles.subTitle}>
              The place where you can securely store your passport, drivers
              license and many more.
            </Typography>
            <ScrollLink
              to="usecases" // Replace with the appropriate name of the section to scroll to
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed for your layout
              duration={500} // Duration of the scroll animation
            >
              <Button variant="text" style={primaryButtonStyle}>
                To all use-cases
                <ExpandMoreIcon />
              </Button>
            </ScrollLink>

            <div style={dynamicContainerStyle} id="about">
              <div style={styles.box}>
                <p style={styles.boxTitle}>4 Key Principles</p>
                <div style={styles.row}>
                  <p style={styles.number}>1</p>
                  <div style={styles.column}>
                    <p style={styles.boxSubTitle}>Convenience</p>
                    <p style={styles.boxText}>
                      Accessible at any time at your mobile Device
                    </p>
                  </div>
                </div>
                <div style={styles.row}>
                  <p style={styles.number}>2</p>
                  <div style={styles.column}>
                    <p style={styles.boxSubTitle}>Security</p>
                    <p style={styles.boxText}>
                      Built on Hyperledger, DeWa conforms to the EU Digital
                      Wallet Framework and eIDAS 2.0
                    </p>
                  </div>
                </div>
                <div style={styles.row}>
                  <p style={styles.number}>3</p>
                  <div style={styles.column}>
                    <p style={styles.boxSubTitle}>Data Ownership</p>
                    <p style={styles.boxText}>
                      Users can keep track of where their data is used
                    </p>
                  </div>
                </div>
                <div style={styles.row}>
                  <p style={styles.numberBottom}>4</p>
                  <div style={styles.column}>
                    <p style={styles.boxSubTitle}>Decentralisation</p>
                    <p style={styles.boxTextBottom}>
                      Users data is not controlled by central authorities
                    </p>
                  </div>
                </div>
              </div>
              <div style={styles.box}>
                <p style={styles.boxTitle}>DeWa Wallet</p>
                <Wrapper>
                  <ThumbnailImg
                    src={wallet}
                    alt="wallet"
                    style={styles.image}
                    onClick={() => handleImageClick(wallet)}
                  />

                  {/* Full-screen image container */}
                  {isImageVisible && (
                    <ImageContainer
                      onClick={closeImage}
                      visible={isImageVisible}
                    >
                      <EnlargedImg src={imageSrc} alt="enlarged" />
                    </ImageContainer>
                  )}
                </Wrapper>
              </div>
            </div>
            <Typography variant="h1" style={styles.title} id="usecases">
              5 Use Cases
            </Typography>
            <Typography variant="h1" style={styles.subTitle}>
              To revolutionize Identity Management
            </Typography>
            <div style={styles.uContainer}>
              <div style={styles.box}>
                <URow>
                  {Object.keys(subtitlesContent).map((key) => {
                    const subtitleClassName =
                      selected === key
                        ? `boxSubTitle boxSubTitle-${key
                            .toLowerCase()
                            .replace(/\s/g, "")} uppercase-text`
                        : "boxSubTitle uppercase-text";
                    return (
                      <p
                        key={key}
                        className={subtitleClassName}
                        onClick={() => setSelected(key)}
                      >
                        {key}
                      </p>
                    );
                  })}
                </URow>

                <div style={styles.boxSubTitle}>
                  {subtitlesContent[selected]}
                </div>
              </div>
            </div>
            <Wrapper2>
              <Typography variant="h1" style={styles.title} id="partners">
                Our Partners
              </Typography>
              <Typography variant="h1" style={styles.subTitle}>
                We highly value our partners & collaborators
              </Typography>

              {/* Partner boxes container */}
              <PartnerBoxesContainer>
                {partnerData.map((partner, index) => (
                  <PartnerBox
                    key={index}
                    onClick={() => openPartnerWebsite(partner.websiteUrl)}
                  >
                    <PartnerImage src={partner.imageUrl} alt={partner.name} />
                  </PartnerBox>
                ))}
              </PartnerBoxesContainer>
            </Wrapper2>
            <Typography variant="h1" style={styles.title} id="imprint">
              Imprint
            </Typography>
            <Typography variant="h1" style={styles.subTitle}>
              Find us at the Copenhagen Fintech Lab
            </Typography>
            <div style={styles.subTitle2}>
              Applebys Plads 7, 1411 København K
            </div>
            <div style={styles.mailFirst}>
              <a href="mailto:thomas@decentralisedwallet.eu" class="email-link">
                thomas@decentralisedwallet.eu
              </a>
            </div>
            <div style={styles.mail}>
              <a href="mailto:lorenz@decentralisedwallet.eu" class="email-link">
                lorenz@decentralisedwallet.eu
              </a>
            </div>
            <div style={styles.mail}>
              <a href="mailto:maciej@decentralisedwallet.eu" class="email-link">
                maciej@decentralisedwallet.eu
              </a>
            </div>
            <div style={styles.mail}>
              <a href="mailto:anis@decentralisedwallet.eu" class="email-link">
                anis@decentralisedwallet.eu
              </a>
            </div>
            <div style={styles.mailLast}>
              <a href="mailto:theo@decentralisedwallet.eu" class="email-link">
                theo@decentralisedwallet.eu
              </a>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

const EnlargedImg = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  cursor: pointer;
`;

const ThumbnailImg = styled.img`
  max-width: 100px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;
const URow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    /* Add responsive styles for smaller screens here */
  }
`;

const ReactiveText = styled.div`
  color: rgba(249, 249, 249);
  font-size: 24px;
  padding: 20px 20px 30px 0px;
  margin-bottom: 10px;
  margin-left: 20px;
  line-height: 2;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 10px 30px 0px;
    line-height: 1.7;
  }
`;

const ReactiveSubTitle = styled.div`
  color: rgba(249, 249, 249);
  font-weight: medium;
  font-size: 32px;
  padding: 20px 20px 0px 20px;
  margin-left: 20;
  margin-right: 20;
  margin-top: 30;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 10px 10px 0px 0.5rem0px;
    line-height: 1.3;
  }
`;

const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column; /* Display elements in a column */
  align-items: center;
  padding: 20px;
  background-color: #fff; /* Add a background color to the overall section */
`;

const PartnerBoxesContainer = styled.div`
  display: flex;
  flex-direction: row; /* Display boxes in a row on large screens */
  flex-wrap: wrap; /* Allow boxes to wrap to the next row on small screens */
  justify-content: center;
`;

const PartnerBox = styled.div`
  width: 350px;
  height: 350px;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: contain;
  padding: 30px;
`;

const PartnerImage = styled.img`
  max-width: 100%; /* Set the maximum width to 100% */
  max-height: 100%; /* Set the maximum height to 100% */
  width: auto; /* Auto-adjust the width to maintain aspect ratio */
  height: auto;
`;

const styles = {
  title: {
    fontWeight: "bold",
    fontSize: "50px",
    marginTop: 150,
    padding: 20,
  },
  subTitle: {
    fontWeight: "medium",
    fontSize: "30px",
    padding: "0px 20px 20px 20px", // Top, Right, Bottom, Left
    marginLeft: 20,
    marginRight: 20,
  },
  subTitle2: {
    fontWeight: "medium",
    fontSize: "30px",
    padding: "20px 20px 0 20px",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 40,
  },
  mailFirst: {
    fontWeight: "300",
    fontSize: "20px",
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
    marginTop: 40,
  },
  mail: {
    fontWeight: "300",
    fontSize: "20px",
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
  },
  mailLast: {
    fontWeight: "300",
    fontSize: "20px",
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
    marginBottom: 100,
  },
  content: {
    // Your common styles here
    display: "flex",
    flexDirection: "column",
  },
  text: {
    color: "rgba(249, 249, 249)",
    fontSize: 22,
    padding: "20px 20px 0 20px", // Top, Right, Bottom, Left
    marginBottom: 10,
    marginLeft: 20,
    lineHeight: 2.0,
    fontWeight: "normal",
  },
  text2: {
    color: "rgba(249, 249, 249)",
    fontSize: 22,
    padding: "20px 20px 0 20px", // Top, Right, Bottom, Left
    marginBottom: 30,
    marginLeft: 20,
    lineHeight: 2.0,
    fontWeight: "normal",
  },
  image: {
    maxHeight: "80%",
    maxWidth: "100%", // Ensures the image width doesn't exceed the box width
    width: "auto", // Maintains the aspect ratio of the image
    height: "auto", // Maintains the aspect ratio of the image
    objectFit: "contain", // Ensures the image fits within the given dimensions without stretching
  },
  row: {
    display: "flex",
    flexDirection: "row", // Align children in a row
    alignItems: "center", // Align children vertically in the center
    marginBottom: "1rem", // Optional: adds some space below the row
  },
  uRow: {
    display: "flex",
    "@media (max-width: 768px)": {
      // example breakpoint for mobile view
      flexDirection: "column", // stack in a column on mobile
    }, // Display subtitles in a row by default
    alignItems: "center", // Align children vertically in the center
    marginBottom: "1rem", // Optional: adds some space below the row
    cursor: "pointer",
    justifyContent: "space-between",
    padding: 30,
  },
  number: {
    paddingLeft: 30,
    color: "white",
    fontWeight: "bold",
    fontSize: "1.9rem", // Adjust the size as needed
    marginRight: "0.5rem", // Adjust the space between the number and the column
  },
  numberBottom: {
    paddingLeft: 30,
    color: "white",
    fontWeight: "bold",
    fontSize: "1.9rem", // Adjust the size as needed
    marginRight: "0.5rem", // Adjust the space between the number and the column
    marginBottom: 50,
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  boxSubTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem", // Adjust the size as needed
    marginBottom: "0.25rem", // Space below the subtitle
    color: "rgba(249, 249, 249)",
    paddingLeft: 20,
    marginTop: 20,
  },
  container: {
    display: "flex",
    flexDirection: "row", // default to row layout
    padding: "40px",
    gap: "20px", // to keep some space between the columns
  },
  uContainer: {
    display: "flex",
    margin: 30,
    gap: "20px", // to keep some space between the columns
  },
  box: {
    flex: 1, // both boxes take up equal space
    backgroundColor: "rgba(0, 0, 0, 0.76)", // black with opacity
    borderRadius: "20px", // rounded corners
    textAlign: "left",
    // additional styling here
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  },
  boxTitle: {
    color: "rgba(249, 249, 249)",
    paddingLeft: 30,
    paddingTop: 30,
    paddingBottom: 20,
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 10,
  },
  boxText: {
    color: "rgba(249, 249, 249)",
    fontSize: 18,
    marginTop: 5,
    marginBottom: 10,
    padding: "0px 20px 0 20px", // Top, Right, Bottom, Left
  },
  boxTextBottom: {
    color: "rgba(249, 249, 249)",
    fontSize: 18,
    padding: "0px 20px 0 20px", // Top, Right, Bottom, Left
    marginTop: 5,
    marginBottom: 70,
  },
  boxContainer: {
    "@media (max-width: 768px)": {
      // example breakpoint for mobile view
      flexDirection: "column", // stack in a column on mobile
    },
  },
};
